import { Box, Typography } from '@mui/material'
import { useDeleteComment } from 'api/hooks/delete'
import { AvatarUser } from 'components/common/Avatar'
import EllipsisMenu from 'components/common/EllipsisMenu'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import LexicalEditor from 'inputs/LexicalEditor'
import { useState } from 'react'
import { formatDate, getUserName } from 'utils'
import { useAuth } from '../../../hooks/useAuth'
import CommentEdit from './CommentEdit'

const Comment = ({ comment }) => {
  const { user } = useAuth()
  const { fetchEvent, fetchUsers } = useEvent()
  const [isEditable, setIsEditable] = useState(false)
  const { mutateAsync: deleteComment } = useDeleteComment()

  const { id: commentId, user_id, created_at: postDate, body } = comment

  const ellipsisMenuItems = [
    {
      name: 'Edit',
      onClick: () => setIsEditable(true),
    },
    {
      name: 'Delete',
      confirm: true, // brings up confirmation modal
      onClick: () => deleteComment({ user_id, comment_id: commentId }),
    },
  ]

  return (
    <Flex row position="relative" gap={['12px', '18px']} alignItems="flex-start">
      <AvatarUser userId={user_id} />
      <Flex width="100%" gap="8px">
        <Flex row gap="8px">
          <Typography fontWeight="bold">
            {getUserName(fetchUsers.data[user_id])} {fetchEvent.data.hostId === user_id && '(host)'}
          </Typography>
          <Typography variant="body2" fontSize="15px">
            {formatDate(postDate, 'Comment')}
          </Typography>
        </Flex>
        {isEditable ? (
          <CommentEdit comment={comment} setIsEditable={setIsEditable} />
        ) : (
          <LexicalEditor value={body} viewMode />
        )}
      </Flex>
      {!isEditable && (
        <Box position="absolute" top="0" right="0">
          {user.id === user_id && (
            <EllipsisMenu sx={{ fontSize: '20px' }} items={ellipsisMenuItems} />
          )}
        </Box>
      )}
    </Flex>
  )
}

export default Comment
