import { LoadingButton } from '@mui/lab'
import { Button, Typography } from '@mui/material'
import { useGetEvent } from 'api/hooks/get'
import { usePatchEvent } from 'api/hooks/patch'
import { usePostEvent } from 'api/hooks/post'
import Flex from 'components/common/Flex'
import dayjs from 'dayjs'
import { useAuth } from 'hooks/useAuth'
import { DatePicker, TextEditor, TextField, TimePicker } from 'inputs/ControlledInputs'
import { useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserName, getUTCDateTime } from 'utils'

const EventCreateEdit = () => {
  const { pathname } = useLocation()
  const isCreate = pathname.substring(pathname.lastIndexOf('/') + 1) === 'create'
  const { user } = useAuth()
  const fetchEvent = useGetEvent(!isCreate)
  const event = fetchEvent.data
  const isHost = event?.hostId === user.id
  const navigate = useNavigate()

  if (!isCreate && !isHost) {
    navigate(`/events/${event.id}`)
    return null
  }

  const editorRef = useRef(null)
  const { mutateAsync: patchEvent } = usePatchEvent()
  const { mutateAsync: postEvent } = usePostEvent()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm({
    defaultValues: {
      title: event.title ?? '',
      host: event.host ?? getUserName(user),
      location: event.location ?? '',
      max_attendees: event.max_attendees ?? 25,
      start_date: event.start_date_time ? dayjs(event.start_date_time) : null,
      start_time: event.start_date_time ? dayjs(event.start_date_time) : null,
      end_date: event.end_date_time ? dayjs(event.end_date_time) : null,
      end_time: event.end_date_time ? dayjs(event.end_date_time) : null,
      description: event.description ?? null,
    },
  })

  const onSubmit = async (formValues) => {
    const hasEndDate = formValues.end_date
    const event = {
      title: formValues.title,
      start_date_time: getUTCDateTime(formValues.start_date, formValues.start_time),
      end_date_time: hasEndDate
        ? getUTCDateTime(formValues.end_date, formValues.end_time ?? formValues.end_date)
        : undefined,
      host: formValues.host,
      location: formValues.location,
      max_attendees: Number(formValues.max_attendees),
      description: JSON.stringify(editorRef.current.getEditorState()),
    }

    isCreate ? await postEvent(event) : await patchEvent(event)
  }

  return (
    <Flex component="form" sx={style.container} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" alignSelf="center" mb="16px">
        {isCreate ? 'Plan an' : 'Edit your'} event
      </Typography>
      <TextField
        control={control}
        name="title"
        label="Event name"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <TextField
        control={control}
        name="host"
        label="Hosted by"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <TextField
        control={control}
        name="location"
        label="Location"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <TextField
        control={control}
        name="max_attendees"
        label="Max attendees"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
        type="number"
      />
      <Flex row gap="8px" alignItems="flex-start">
        <DatePicker
          control={control}
          name="start_date"
          label="Start date"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
        />
        <TimePicker
          control={control}
          name="start_time"
          label="Start time"
          disabled={isSubmitting}
          rules={{ required: 'Required' }}
        />
      </Flex>
      <Flex row gap="8px" alignItems="flex-start">
        <DatePicker
          control={control}
          name="end_date"
          label="End date (optional)"
          disabled={isSubmitting}
          referenceDate={watch('start_date')}
        />
        <TimePicker
          control={control}
          name="end_time"
          label="End time (optional)"
          disabled={isSubmitting}
        />
      </Flex>
      <TextEditor
        control={control}
        name="description"
        placeholder="Event description"
        disabled={isSubmitting}
        editorRef={editorRef}
        rules={{ required: 'Required' }}
      />
      <Flex row alignSelf="center" gap="16px">
        {!isCreate && (
          <Button variant="text" onClick={() => navigate(-1)} disabled={isSubmitting}>
            Cancel
          </Button>
        )}
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {isCreate ? 'Create' : 'Edit'} event
        </LoadingButton>
      </Flex>
    </Flex>
  )
}

export default EventCreateEdit

const style = {
  container: {
    maxWidth: '600px',
    width: '100%',
    margin: '0 auto',
    padding: ['16px', '32px'],
    gap: '16px',
  },
}
