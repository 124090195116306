export const getObjectClone = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}

export const calculateJoiningTime = (created_at) => {
  const joinDate = new Date(created_at)
  const currentDate = new Date()

  // Calculate the difference in milliseconds
  const difference = currentDate - joinDate

  // Convert milliseconds to days
  const days = Math.floor(difference / (1000 * 60 * 60 * 24))

  if (days < 1) {
    return 'Joined today'
  } else if (days < 30) {
    return `Joined ${days} days ago`
  } else {
    // Convert days to months
    const months = Math.floor(days / 30)

    if (months < 1) {
      return `Joined ${days} days ago`
    } else if (months < 12) {
      return `Joined ${months} months ago`
    } else {
      // Convert months to years
      const years = Math.floor(months / 12)
      return years === 1 ? `Joined ${years} year ago` : `Joined ${years} years ago`
    }
  }
}

export const formatPhoneNumber = (value, type = 'formatted') => {
  if (!value) return value
  let phoneNumber = value.replace(/[^\d]/g, '')

  if (type === 'raw') return phoneNumber

  const phoneNumberLength = phoneNumber.length
  if (phoneNumberLength < 4) return phoneNumber
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export const getEventIdfromLink = (url, save = true) => {
  const regex = /events\/([a-zA-Z0-9_]+)/
  const match = url.match(regex)
  const eventId = match ? match[1] : null

  // use localstorage instead of sessionstorage because safari doesnt support sessionstorage in private mode
  save && localStorage.setItem('eventId', eventId)

  return eventId
}

export const handleLoginNavigation = (navigate) => {
  // check for eventId if coming from external link for redirect
  const eventId = localStorage.getItem('eventId')
  if (eventId) {
    localStorage.removeItem('eventId')
    navigate(`/events/${eventId}`)
  } else {
    navigate('/events')
  }
}

export const formatDate = (input, style) => {
  const date = new Date(input)

  const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const dayOfWeek = days[date.getDay()]
  const month = months[date.getMonth()]
  const dayOfMonth = date.getDate()
  const year = date.getFullYear()
  let hours = date.getHours()
  let minutes = date.getMinutes().toString().padStart(2, '0') // ensures double digits

  const period = hours < 12 ? 'AM' : 'PM'
  hours = hours % 12
  hours = hours ? hours : 12

  const sentenceCase = (str) => {
    return str.toLowerCase().charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }

  switch (style) {
    case 'EventCard':
      return `${capitalize(dayOfWeek)}, ${month} ${dayOfMonth} ${year} at ${hours} ${period}`
    case 'Comment':
      return `${month} ${date.getDate()} ${hours}:${minutes} ${period}`
    case 'PollCard':
      return `${sentenceCase(month)} ${dayOfMonth}`
    default:
      return date
  }
}

export const getUTCDateTime = (dateString, timeString) => {
  if (!dateString || !timeString) return null

  // Parse date and time strings
  const date = new Date(dateString)
  const time = new Date(timeString)

  // Extract date components
  const year = date.getUTCFullYear()
  const month = String(date.getUTCMonth() + 1).padStart(2, '0')
  const day = String(date.getUTCDate()).padStart(2, '0')

  // Extract time components
  const hours = String(time.getUTCHours()).padStart(2, '0')
  const minutes = String(time.getUTCMinutes()).padStart(2, '0')
  const seconds = String(time.getUTCSeconds()).padStart(2, '0')
  const milliseconds = String(time.getUTCMilliseconds()).padStart(3, '0')

  // Construct final string
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`
}

export const createQueryString = (filters) => {
  if (!filters) return ''

  const defaultFilters = { limit: '20', offset: '0' }
  const newFilters = { ...defaultFilters, ...filters }

  return (
    '?' +
    Object.entries(newFilters)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')
  )
}

export const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()

export const displayDateTime = (date) => {
  return new Date(date).toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  })
}

export const getUserName = (user) => user.first_name + ' ' + user.last_name
