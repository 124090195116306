import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, Checkbox, TextField, Typography } from '@mui/material'
import { usePatchTask } from 'api/hooks/patch'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import { useState } from 'react'
import { getUserName } from 'utils'
import { taskFormStyles } from './TaskCreateEditModal'

const TaskProgressModal = ({ task }) => {
  const { fetchUsers } = useEvent()
  const { mutate: editTask, isPending } = usePatchTask()
  const [formValues, setFormValues] = useState(task.assignees)

  const handleChange = (e) => {
    const { name, checked } = e.target
    const [index, prop] = name.split('.')

    setFormValues(
      formValues.map((value, i) => (i === parseInt(index) ? { ...value, [prop]: checked } : value))
    )
  }

  const handleSelectAll = (prop) => {
    const isAllSelected = formValues.every(({ [prop]: value }) => value)

    setFormValues(
      formValues.map((value) => ({
        ...value,
        [prop]: !isAllSelected,
      }))
    )
  }

  const handleSubmit = () => {
    editTask({
      taskPayload: { assignees: formValues, message: task.message },
      task,
    })
  }

  return (
    <Flex gap="16px">
      <Typography variant="h6" alignSelf="center">
        Edit task progress
      </Typography>
      <TextField name="message" value={task.message} disabled />
      <Flex gap="8px">
        <Flex row justifyContent="space-between">
          <Typography fontWeight="bold">Assignees</Typography>
          <Box>
            <Button
              variant="text"
              sx={{ fontSize: '14px' }}
              onClick={() => handleSelectAll('reminder_sent')}
              disabled={isPending}
            >
              Reminder
            </Button>
            <Button
              variant="text"
              sx={{ fontSize: '14px' }}
              onClick={() => handleSelectAll('is_completed')}
              disabled={isPending}
            >
              Completed
            </Button>
          </Box>
        </Flex>
        <Flex sx={taskFormStyles.assignees}>
          {task.assignees.map(({ user_id: userId }, i) => (
            <Flex row sx={taskFormStyles.assignee} key={userId}>
              <Typography>{getUserName(fetchUsers.data[userId])}</Typography>
              <Flex row gap="40px">
                <Checkbox
                  name={`${i}.reminder_sent`}
                  checked={formValues[i].reminder_sent}
                  value={formValues[i].reminder_sent}
                  onChange={handleChange}
                  disabled={task.assignees[i].reminder_sent || isPending}
                />
                <Checkbox
                  name={`${i}.is_completed`}
                  checked={formValues[i].is_completed}
                  value={formValues[i].is_completed}
                  onChange={handleChange}
                  disabled={isPending}
                />
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
      <LoadingButton sx={{ alignSelf: 'flex-end' }} onClick={handleSubmit} loading={isPending}>
        Save
      </LoadingButton>
    </Flex>
  )
}

export default TaskProgressModal
