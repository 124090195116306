import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button, FormControl, FormHelperText, Typography } from '@mui/material'
import { usePatchTask } from 'api/hooks/patch'
import { usePostTask } from 'api/hooks/post'
import Flex from 'components/common/Flex'
import { useEvent } from 'contexts/EventContext'
import dayjs from 'dayjs'
import { Checkbox, DatePicker, TextField, TimePicker } from 'inputs/ControlledInputs'
import { useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { getUserName, getUTCDateTime } from 'utils'

const TaskCreateEditModal = ({ task = {} }) => {
  const isCreateTask = !task.message
  const { fetchRsvps, fetchUsers } = useEvent()
  const { mutateAsync: createTask } = usePostTask()
  const { mutateAsync: editTask } = usePatchTask()
  const rsvps = fetchRsvps.data.filter(({ attending }) => attending === 'YES')

  const taskAssigneesMap = task.assignees
    ? Object.fromEntries(task.assignees.map((assignee) => [assignee.user_id, assignee]))
    : {}

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
    setError,
    clearErrors,
  } = useForm({
    defaultValues: {
      message: task.message,
      due_date: task.respond_by ? dayjs(task.respond_by) : null,
      due_time: task.respond_by ? dayjs(task.respond_by) : null,
      assignees: Object.fromEntries(
        rsvps.map(({ user_id }) => [user_id, !!taskAssigneesMap[user_id]])
      ),
    },
  })

  // useWatch > watch since it watches a specific input
  const assignees = useWatch({
    control,
    name: 'assignees',
  })
  const isAllAssigneesChecked = Object.values(assignees).every(Boolean)
  const handleSelectAll = () => {
    Object.keys(assignees).forEach((userId) => {
      setValue(`assignees.${userId}`, !isAllAssigneesChecked)
    })
  }

  // show error if no assignees are selected on isDirty (closest I could get to default behavior)
  useEffect(() => {
    if (isDirty && !Object.values(assignees).some(Boolean)) {
      setError('assignees', {
        type: 'manual',
        message: 'At least one assignee must be selected',
      })
    } else {
      clearErrors('assignees')
    }
  }, [assignees, isDirty, setError, clearErrors])

  const onSubmit = async (data) => {
    if (isCreateTask) {
      await createTask({
        message: data.message,
        respond_by:
          data.due_date && data.due_time ? getUTCDateTime(data.due_date, data.due_time) : null,
        assignees: Object.entries(data.assignees)
          .filter(([_, isAssigned]) => isAssigned)
          .map(([userId]) => userId),
      })
    } else {
      await editTask({
        taskPayload: {
          message: data.message,
          respond_by:
            data.due_date && data.due_time ? getUTCDateTime(data.due_date, data.due_time) : null,
          assignees: Object.entries(data.assignees)
            .filter(([_, isAssigned]) => isAssigned)
            .map(
              ([userId]) =>
                taskAssigneesMap[userId] ?? {
                  user_id: userId,
                  is_completed: false,
                  completion_date_time: null,
                  reminder_sent: false,
                }
            ),
        },
        task,
      })
    }
  }

  return (
    <Flex
      component="form"
      sx={taskFormStyles.container}
      onSubmit={handleSubmit(onSubmit)}
      gap="16px"
    >
      <Typography variant="h6" alignSelf="center">
        {!task.id ? 'Create a task' : 'Edit task'}
      </Typography>
      <TextField
        control={control}
        name="message"
        label="Description"
        disabled={isSubmitting}
        rules={{ required: 'Required' }}
      />
      <Flex row gap="8px">
        <DatePicker control={control} name="due_date" label="Due date" disabled={isSubmitting} />
        <TimePicker control={control} name="due_time" label="Due time" disabled={isSubmitting} />
      </Flex>
      <Flex gap="8px">
        <Flex row justifyContent="space-between">
          <Typography fontWeight="bold">Assignee(s)</Typography>
          <Button variant="text" onClick={handleSelectAll} disabled={isSubmitting}>
            {isAllAssigneesChecked ? 'Unselect all' : 'Select all'}
          </Button>
        </Flex>
        <Box sx={taskFormStyles.assignees}>
          {rsvps?.map(({ user_id: userId }) => (
            <Flex sx={taskFormStyles.assignee} key={userId}>
              <Typography>{getUserName(fetchUsers.data[userId])}</Typography>
              <Checkbox name={`assignees.${userId}`} control={control} disabled={isSubmitting} />
            </Flex>
          ))}
        </Box>
        {errors.assignees?.message && (
          <FormControl error>
            <FormHelperText sx={{ m: 0 }}>{errors.assignees.message}</FormHelperText>
          </FormControl>
        )}
      </Flex>
      <LoadingButton
        type="submit"
        sx={{ alignSelf: 'flex-end' }}
        onClick={handleSubmit}
        loading={isSubmitting}
      >
        {isCreateTask ? 'Create' : 'Save'}
      </LoadingButton>
    </Flex>
  )
}

export default TaskCreateEditModal

export const taskFormStyles = {
  assignees: {
    backgroundColor: 'divider',
    overflowY: 'auto',
    maxHeight: '30vh',
  },
  assignee: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '8px',
    height: '32px',
    overflowY: 'hidden',
  },
}
